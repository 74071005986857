import { Column, Columns } from 'bloomer';
import { times } from 'lodash';
import React, { PureComponent } from 'react';

import Button from 'components/Button';
import ShadowHeading from 'components/ShadowHeading';
import { IBundleConfig } from 'lib/bundles';
import { BLACK } from 'lib/colors';
import { bundleRoute } from 'routes';
import { BundlesQuery_bundles_products } from 'schema';

import './styles.scss';

const NUM_PRODUCTS = 3;

export default class Bundle extends PureComponent<IProps> {
  renderProducts = () => {
    const { bundleConfig, products } = this.props;

    const imgProps = {
      className: 'productImg',
      style: { boxShadow: `4px 3px ${bundleConfig.secondaryColor}` },
    };

    if (products) {
      return products.slice(0, NUM_PRODUCTS).map((product) => (
        <img key={product.id} src={product.imageUrl || ''} alt={product.name} {...imgProps} />
      ));
    }

    return times(NUM_PRODUCTS, (i) => (
      <div key={i} {...imgProps} />
    ));
  }

  render() {
    const { bundleConfig } = this.props;

    return (
      <Columns isGapless isMarginless className='bundleContainer'>
        <Column isSize={6}>
          <img src={`/bundles/${bundleConfig.imageName}`} alt={bundleConfig.displayName} className='mainImg' />
        </Column>
        <Column isSize={6} className='textContainer'>
          <ShadowHeading type='h3' textColor={BLACK} shadowColor={bundleConfig.mainColor}>{bundleConfig.displayName}</ShadowHeading>
          <h4>{bundleConfig.blurb}</h4>
          <div className='productImages'>
            {this.renderProducts()}
          </div>
          <Button
            title='Explore this bundle &rarr;'
            href={bundleRoute(bundleConfig.slug)}
            color={bundleConfig.secondaryColor}
            slideColor={bundleConfig.mainColor}
          />
        </Column>
      </Columns>
    );
  }
}

interface IProps {
  bundleConfig: IBundleConfig;
  products?: BundlesQuery_bundles_products[];
}