import { find } from 'lodash';
import React from 'react';

import Hero from 'components/Hero';
import ShadowHeading from 'components/ShadowHeading';
import { useBundlesQuery } from 'graphql/queries/bundles';
import { bundleConfigs } from 'lib/bundles';
import { HIGHLIGHT_COLOR, HIGHLIGHT_COLOR_DARK, WHITE } from 'lib/colors';

import Bundle from './Bundle';

const Home = () => {
  const bundlesQuery = useBundlesQuery();

  const bundles = bundlesQuery.data?.bundles;

  const productsForBundle = (slug: string) => (
    find(bundles, (bundle) => bundle.slug === slug)
  );

  return (
    <div className='homeContainer'>
      <Hero backgroundColor={HIGHLIGHT_COLOR} fullWidthImageName='hero-utensils.png'>
        <ShadowHeading type='h1' textColor={WHITE} shadowColor={HIGHLIGHT_COLOR_DARK}>Level up your kitchen</ShadowHeading>
        <h2 className='h2'>Buy the best products with one click</h2>
      </Hero>
      <div id='bundles'>
        {Object.values(bundleConfigs).map((config) => (
          <Bundle bundleConfig={config} products={productsForBundle(config.slug)?.products} key={config.slug} />
        ))}
      </div>
    </div>
  );
};

export default Home;