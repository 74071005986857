import { find } from 'lodash';
import React, { ReactNode, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useViewerQuery } from 'graphql/queries/viewer';
import { bundleConfigs } from 'lib/bundles';
import { ViewerQuery_cart_products as IProduct } from 'schema';

import { ViewerProvider } from './context';
import Footer from './Footer';
import NavigationBar from './NavigationBar';
import PageWrapper from './PageWrapper';

const VIEWER_ID_KEY = 'viewerId';
const CART_ID_KEY = 'cartId';

const NavigationWrapper = (props: IProps) => {
  // Get viewer and cart
  const viewerIdStr = window.localStorage.getItem(VIEWER_ID_KEY);
  const viewerId = viewerIdStr ? JSON.parse(viewerIdStr) : null;
  const cartIdStr = window.localStorage.getItem(CART_ID_KEY);
  const cartId = cartIdStr ? JSON.parse(cartIdStr) : null;

  const viewerQuery = useViewerQuery({ variables: { viewerId, cartId } });
  const viewer = viewerQuery.data?.viewer;
  const cart = viewerQuery.data?.cart;

  // Get number of items in cart
  const [products, setProducts] = useState<IProduct[]>([]);
  const displayNumProductsInCart = React.useMemo(() => {
    if (viewerQuery.loading) {
      return 0;
    }
    if (products != null) {
      return products.length;
    }
    return viewerQuery.data?.cart?.products?.length ?? 0;
  }, [viewerQuery, products]);

  // Store local viewer and cart id
  if (viewer) {
    window.localStorage.setItem(VIEWER_ID_KEY, JSON.stringify(viewer.id));
  }
  if (cart) {
    window.localStorage.setItem(CART_ID_KEY, JSON.stringify(cart.id));
  }

  const addProductsToCart = (newProducts: IProduct[]) => {
    if (!cart) {
      return;
    }

    const updatedProducts = cart.products.concat(newProducts);
    setProducts(updatedProducts);
    cart.products = updatedProducts;
  };

  const removeProductFromCart = (product: IProduct) => {
    if (!cart) {
      return;
    }

    const updatedProducts = cart.products.filter((p) => p.id !== product.id);
    cart.products = updatedProducts;
    setProducts(updatedProducts);
  };

  // Set nav color from selected bundle
  const splitPath = useLocation().pathname.split('/').filter((v) => v);
  const bundleSlug = splitPath.length > 1 ? splitPath[1] : undefined;
  const bundleConfig = bundleSlug ? find(bundleConfigs, (config) => config.slug === bundleSlug) : undefined;

  const { children } = props;
  return (
    <ViewerProvider
      value={{
        viewerContext: {
          viewerId: viewer?.id,
          bundleConfig,
          cart,
          addProductsToCart,
          removeProductFromCart,
        }
      }}
    >
      <NavigationBar />
      <PageWrapper>
        <div className='content'>
          {children}
        </div>
        <Footer />
      </PageWrapper>
    </ViewerProvider>
  );
}

interface IProps {
  children: ReactNode;
}

export default NavigationWrapper;