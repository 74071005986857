import React, { ComponentType, createContext } from 'react';

import { BundleQuery_bundle_products as IProduct } from 'schema';

export interface IProductsConsumer {
  productsContext: {
    products: IProduct[];
    unselectProduct: (product: IProduct, reason: string) => void;
  }
}

const Context = createContext<IProductsConsumer>({} as IProductsConsumer);
export const ProductsProvider = Context.Provider;

export function withProductsContext<T>(Component: ComponentType<T & IProductsConsumer>) {
  return function ContextComponent(props: any) {
    return (
      <Context.Consumer>
        {value => <Component {...props} {...value} />}
      </Context.Consumer>
    );
  }
}