const BUNDLE_SLUG = ':bundleSlug';

const routes: { [key: string]: IRoute } = {
  bundle: { name: 'Bundle', path: `/bundle/${BUNDLE_SLUG}` },
  cart: { name: 'Cart', path: '/cart' },
  home: { name: 'Home', path: '/' },
  playground: { name: '[Dev Playground]', path: '/playground' },
  quiz: { name: 'Quiz', path: '/quiz' },
  cookiesPolicy: { name: 'Cookies Policy', path: '/cookies-policy' },
};

export const bundleRoute = (slug: string) => routes.bundle.path.replace(BUNDLE_SLUG, slug);

interface IRoute {
  name: string,
  path: string,
}

export default routes;