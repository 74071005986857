import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import React, { useEffect } from 'react';
import { ApolloProvider } from 'react-apollo';
import { BrowserRouter as Router, Route, Switch, useLocation } from 'react-router-dom';
import { ThemeProvider } from 'theme-ui';

import Layout from 'components/Layout';
import Bundle from 'containers/Bundle';
import Cart from 'containers/Cart';
import Home from 'containers/Home';
import CookiePolicy from 'containers/Policies/CookiesPolicy';

import routes from './routes';
import './styles.scss';
import theme from './theme';

export const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: createHttpLink({}),
});

const ScrollToTop = () => {
  const { pathname } = useLocation();
  useEffect(() => window.scrollTo(0, 0), [pathname]);
  return null;
}

const App = () => {
  return (
    <Router>
      <ScrollToTop />
      <ApolloProvider client={client}>
        <ThemeProvider theme={theme}>
          <Layout>
            <Switch>
              <Route path={routes.home.path} component={Home} exact />
              <Route path={routes.bundle.path} component={Bundle} />
              <Route path={routes.cart.path} component={Cart} />
              <Route path={routes.cookiesPolicy.path} component={CookiePolicy} />
            </Switch>
          </Layout>
        </ThemeProvider>
      </ApolloProvider>
    </Router>
  );
};

export default App;