import gql from 'graphql-tag';
import { useQuery, QueryHookOptions } from 'react-apollo';

import { BundleQuery_bundle } from 'schema';

const BUNDLE_QUERY = gql`
  query BundleQuery($slug: String!) {
    bundle(slug: $slug) {
      id
      slug
      products {
        id
        name
        stages
        price
        rating
        ratingCount
        imageUrl
        amazonProductUrl
        __typename
      }
      __typename
    }
  }
`;

export interface BundleQuery {
  bundle: BundleQuery_bundle;
};

export const useBundleQuery = (options?: QueryHookOptions) => useQuery<BundleQuery>(BUNDLE_QUERY, options);