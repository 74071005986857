import React, { PureComponent } from 'react';
import moment from 'moment';

import Link from 'components/Link';
import routes from 'routes';

import '../styles.scss';

export default class Footer extends PureComponent {
  static renderCopyrightRow = () => (
    <>
      &copy; {moment().year()} Kabundle
      &middot;
      Made with <span role='img' aria-label='love'>♥️</span> in San Francisco, CA
      &middot;&nbsp;
      <Link href={routes.cookiesPolicy.path} underlined targetBlank isRouterLink>Cookies Policy</Link>
    </>
  )

  static renderDisclosures = () => (
    <div className='disclosures'>
      As an Amazon Associate I earn from qualifying purchases.
      {/*Product prices, ratings, and availability are accurate as of December 20, 2020 and are subject to change.*/}
      {/*Any price and availability information displayed on Amazon.com at the*/}
      {/*time of purchase will apply to the purchase of this product.*/}
    </div>
  );

  render() {
    return (
      <div className='footer'>
        {Footer.renderCopyrightRow()}
        <br /><br />
        {Footer.renderDisclosures()}
      </div>
    );
  }
}