import gql from 'graphql-tag';
import { useMutation, MutationHookOptions } from 'react-apollo';

import { AddProductsToCartMutation } from 'schema';

const ADD_PRODUCTS_TO_CART_MUTATION = gql`
  mutation AddProductsToCartMutation($viewerId: String!, $productIds: [String!]!, $cartId: String) {
    addProductsToCart(viewerId: $viewerId, productIds: $productIds, cartId: $cartId)
  }
`;

export const useAddProductsToCartMutation = (options?: MutationHookOptions) => useMutation<AddProductsToCartMutation>(ADD_PRODUCTS_TO_CART_MUTATION, options);