import classNames from 'classnames';
import React, { PureComponent } from 'react';

import './styles.scss';

export default class Line extends PureComponent<IProps> {
  static defaultProps = {
    topSpacing: false,
    bottomSpacing: false,
  };

  render() {
    const { color, topSpacing, bottomSpacing } = this.props;

    return (
      <hr style={{ borderColor: color }} className={classNames(topSpacing && 'lineWithTopSpacing', bottomSpacing && 'lineWithBottomSpacing')} />
    );
  }
}

interface IProps {
  color: string;
  topSpacing?: boolean;
  bottomSpacing?: boolean;
}