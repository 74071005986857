const BLACK = '#000000';
const GRAY = '#9B9B9B';
const WHITE = '#FFFFFF';

const HIGHLIGHT_COLOR = '#9CB7FF';
const HIGHLIGHT_COLOR_DARK = '#4E5B80';

const CORAL = '#FF9782';
const CORAL_DARK = '#806B67';

const LAVENDER = '#C9BDE8';
const LAVENDER_DARK = '#443569';

const LIME = '#CCEA81';
const LIME_DARK = '#676B5B';

const PINK = '#F2D7ED';
const PINK_DARK = '#A391A0';

const TEAL = '#98BFA9';
const TEAL_DARK = '#708C7C';

const YELLOW = '#FFEB8F';
const YELLOW_DARK = '#807547';

const MAROON = '#D66758';
const MAROON_DARK = '#96483E';

export {
  BLACK,
  GRAY,
  WHITE,
  HIGHLIGHT_COLOR, HIGHLIGHT_COLOR_DARK,
  CORAL, CORAL_DARK,
  LAVENDER, LAVENDER_DARK,
  LIME, LIME_DARK,
  PINK, PINK_DARK,
  TEAL, TEAL_DARK,
  YELLOW, YELLOW_DARK,
  MAROON, MAROON_DARK,
}