import React, { PureComponent } from 'react';

import Card from 'components/Card';
import { IProductsConsumer, withProductsContext } from 'containers/Bundle/context';

import './styles.scss';

class ProductCardSkeleton extends PureComponent<IProps & IProductsConsumer> {
  static defaultProps = {
    twoButtonRows: false,
  }

  render() {
    const { color, twoButtonRows } = this.props;

    return (
      <Card color={color} className='productCardSkeleton'>
        <div className='imgContainer'>
          <img src='/placeholder.png' alt='Loading' className='image' />
        </div>
        <div className='info1' />
        <div className='info2' />
        {twoButtonRows && <div className='info3' />}
      </Card>
    );
  }
}

interface IProps {
  color: string;
  twoButtonRows?: boolean;
}

export default withProductsContext<IProps>(ProductCardSkeleton);