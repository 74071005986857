import classNames from 'classnames';
import React, { PureComponent, ReactNode } from 'react';

import { WHITE } from 'lib/colors';

import './styles.scss';

export default class Hero extends PureComponent<IProps> {
  static defaultProps = {
    backgroundColor: WHITE,
    fullWidthImageName: undefined,
    centeredImageName: undefined,
    children: undefined,
  };

  render() {
    const { backgroundColor, fullWidthImageName, centeredImageName, children } = this.props;

    const styles = {
      backgroundImage: `url('/${fullWidthImageName}')`,
      backgroundColor,
    };

    if (fullWidthImageName) {
      // Show large hero image with vertically-centered children, more bottom margin
      return (
        <div className={classNames('hero', 'fullSize')} style={styles}>
          <div className={classNames('content', 'topPadding')}>
            {children}
          </div>
        </div>
      );
    }

    // Show centered image at half content width and children, less bottom margin
    return (
      <div className={classNames('hero')} style={styles}>
        <div className={classNames('content')}>
          {centeredImageName && <img src={centeredImageName} alt='Kabundle: Level up your kitchen. Find the best products with one click.' className='centeredImg' />}
          {children}
        </div>
      </div>
    );
  }
}

interface IProps {
  backgroundColor?: string;
  fullWidthImageName?: string;
  centeredImageName?: string;
  children: ReactNode;
}