import React, { PureComponent } from 'react';

const iconProps = {
  xmlns: 'http://www.w3.org/2000/svg',
  xmlnsXlink: 'http://www.w3.org/1999/xlink',
  fill: 'currentColor',
}

export class LogoIcon extends PureComponent<{size: number}> {
  render() {
    const { size } = this.props;
    return (
      <svg viewBox='0 0 64 64' width={size} height={size} {...iconProps}>
        <polygon points="30.343,41.491 10.329,33.485 3,40 22,49 "/>
        <polygon points="53.671,33.485 33.657,41.491 42,49 61,40 "/>
        <polygon points="31,43.591 22.348,51.378 13,46.95 13,53 31,60.579 "/>
        <polygon points="51,46.95 41.652,51.378 33,43.591 33,60.579 51,53 "/>
        <path d="M20.367,5.591c-0.113-0.521-0.35-0.924-0.704-1.196c-0.484-0.372-1.046-0.392-1.42-0.376  c-2.018,0.089-3.884,1.324-4.755,3.146l1.805,0.862c0.548-1.146,1.769-1.955,3.039-2.011c0.03-0.001,0.056-0.002,0.078-0.002  c0.003,0,0.005,0.001,0.005,0.002c0.124,0.568-0.108,1.213-0.377,1.961c-0.188,0.52-0.381,1.058-0.48,1.646  c-0.033,0.201-0.135,0.814,0.262,1.344c0.317,0.422,0.818,0.644,1.479,0.612c0.743-0.019,1.434-0.174,2.103-0.324  c0.521-0.117,1.021-0.229,1.543-0.26l-0.77,3.343c-0.066,0.286-0.176,0.765,0.025,1.278c0.372,0.945,1.456,1.217,2.336,1  c0.174-0.043,0.347-0.102,0.518-0.162c0.189-0.066,0.376-0.153,0.491-0.129c0.213,0.01,0.519,0.51,0.594,1.139  c0.021,0.177,0.036,0.357,0.05,0.539c0.068,0.849,0.146,1.811,0.802,2.62l1.553-1.261c-0.265-0.327-0.312-0.906-0.361-1.52  c-0.017-0.207-0.034-0.414-0.058-0.616c-0.167-1.39-0.999-2.826-2.48-2.899c-0.492-0.023-0.913,0.121-1.25,0.239  c-0.079,0.028-0.158,0.056-0.238,0.081l0.832-3.608c0.053-0.229,0.164-0.703-0.074-1.19c-0.151-0.312-0.556-0.84-1.557-0.868  c-0.858-0.024-1.635,0.152-2.393,0.323c-0.451,0.102-0.884,0.199-1.342,0.245c0.079-0.287,0.186-0.584,0.297-0.894  C20.244,7.748,20.614,6.72,20.367,5.591z"/>
        <path d="M2.255,17.07l1.966,0.369c0.298-1.589,1.698-3.007,3.484-3.528c1.613-0.471,3.566-0.28,5.498,0.539  c1.956,0.829,3.672,2.159,4.962,3.848l1.589-1.214c-1.5-1.964-3.496-3.512-5.771-4.476c-2.354-0.997-4.782-1.217-6.838-0.617  C4.616,12.729,2.696,14.723,2.255,17.07z"/>
        <rect x="30.129" y="8.401" transform="matrix(0.9172 -0.3985 0.3985 0.9172 -1.9766 13.352)" width="2" height="6.059"/>
        <path d="M36.226,15.844c0.81-1.672,1.861-3.21,3.124-4.571l-1.466-1.36c-1.398,1.506-2.562,3.208-3.458,5.059L36.226,15.844z"/>
        <path d="M33.317,9.443l1.996-0.118c-0.095-1.608,0.349-3.229,1.25-4.564l-1.657-1.119C33.76,5.339,33.195,7.399,33.317,9.443z"/>
        <path d="M58.818,21.233l0.363,1.967c0.977-0.18,1.946-0.301,2.881-0.36l-0.125-1.996C60.923,20.908,59.874,21.039,58.818,21.233z"/>
        <path d="M52,32l-6.76-2.366c-1.631,1.27-3.117,2.655-4.36,4.13l-1.53-1.288c1.073-1.274,2.317-2.484,3.669-3.619l-6.461-2.261  c-1.217,1.964-2.257,4.034-3.078,6.2l-1.87-0.709c0.814-2.148,1.838-4.203,3.023-6.165L32,25l-7.392,2.587  c0.763,1.109,1.466,2.321,2.097,3.643l-1.805,0.862c-0.673-1.409-1.428-2.681-2.254-3.819L12,32l20,8L52,32z"/>
        <path d="M38.273,24.049c1.171-1.571,2.31-2.783,3.465-3.676c0.245,1.578,1.369,3.007,2.92,3.509  c2.021,0.656,4.375-0.389,5.247-2.326c0.327-0.726,0.423-1.481,0.279-2.185c-0.255-1.246-1.297-2.297-2.657-2.68  c-0.515-0.145-1.07-0.201-1.659-0.163c2.15-1.79,4.815-2.948,7.595-3.287l-0.242-1.985c-3.481,0.424-6.806,1.98-9.362,4.381  c-0.521,0.489-1.176,1.17-1.631,2.031c-1.923,1.038-3.726,2.726-5.559,5.186c-0.735,0.986-1.402,2.018-2.036,3.068l1.925,0.674  C37.098,25.726,37.661,24.871,38.273,24.049z M43.729,19.327c0.013-0.064,0.029-0.128,0.048-0.192  c0.313-0.141,0.63-0.261,0.951-0.361c0.883-0.276,1.644-0.33,2.26-0.155c0.626,0.176,1.135,0.65,1.238,1.155  c0.058,0.285,0.007,0.627-0.144,0.963c-0.451,1-1.763,1.581-2.807,1.245C44.231,21.642,43.508,20.402,43.729,19.327z"/>
        <path d="M20.91,23.467c-2.719-2.266-5.963-3.545-9.134-3.604c-3.11-0.049-5.877,1.155-7.393,3.245  c-0.944,1.301-1.332,3.053-1.037,4.686c0.257,1.423,1.003,2.609,2.102,3.338l1.106-1.666c-0.641-0.425-1.081-1.146-1.24-2.027  c-0.197-1.091,0.066-2.301,0.688-3.156c1.132-1.561,3.284-2.471,5.736-2.419c2.718,0.05,5.521,1.166,7.891,3.141  c1.093,0.911,2.099,2.008,3.017,3.271l1.962-0.687C23.504,25.983,22.27,24.6,20.91,23.467z"/>
        <path d="M56.094,23.957l-0.581-1.914c-4.506,1.369-8.913,3.808-12.494,6.814l2.221,0.777C48.433,27.148,52.221,25.134,56.094,23.957  z"/>
      </svg>
    );
  }
}

export const BoxIcon = (size: number) => (
  <svg viewBox="0 0 84 90" width={size} height={size} {...iconProps}>
    <path d="M42.062497,0.36965 C42.318357,0.38225 42.551797,0.43495 42.749997,0.49465 L82.749996,16.49465 C83.489696,16.79388 84.008286,17.57176 84.000094,18.36965 L84.000094,72.3696 C83.995996,73.1571 83.480006,73.918 82.749996,74.2133 L42.749997,90.2134 C42.274867,90.4055 41.725127,90.4055 41.249997,90.2134 L1.2499967,74.2133 C0.5199867,73.918 0.0041067,73.1571 -9.46370359e-05,72.3696 L-9.46370359e-05,18.36965 C-0.0080033,17.57176 0.5102967,16.79388 1.2499967,16.49465 L41.249997,0.49465 C41.528337,0.38464 41.806637,0.35708 42.062497,0.36965 Z M41.999997,4.5259 L7.406247,18.36965 L20.093747,23.43215 L52.749996,8.8384 L41.999997,4.5259 Z M61.624996,12.36965 L27.906247,26.55715 L41.999997,32.18215 L76.593747,18.36965 L61.624996,12.36965 Z M79.999996,21.30715 L43.999996,35.71335 L43.999996,85.3696 L79.999996,70.9946 L79.999996,21.30715 Z M3.999997,21.30715 L3.999997,70.9946 L39.999997,85.3696 L39.999997,35.71335 L25.999997,30.11965 L25.999997,45.3696 L17.999997,42.36965 L17.999997,26.9009 L3.999997,21.30715 Z" />
  </svg>
);