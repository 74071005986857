import classNames from 'classnames';
import React, { PureComponent, ReactNode } from 'react';

import './styles.scss';

export default class Card extends PureComponent<IProps> {
  static defaultProps = {
    className: undefined,
  };

  render() {
    const { children, color, className } = this.props;

    return (
      <div className={classNames('card', className)} style={{ boxShadow: `4px 3px 0 ${color}` }}>
        {children}
      </div>
    );
  }
}

interface IProps {
  children: ReactNode;
  color: string;
  className?: string;
}