import classNames from 'classnames';
import React, { PureComponent, ReactNode } from 'react';

import Link from 'components/Link';

import './styles.scss';

export default class Button extends PureComponent<IProps> {
  static defaultProps = {
    onClick: undefined,
    href: undefined,
    slideColor: undefined,
    size: 'large',
    fullWidth: false,
    disabled: false,
    targetBlank: false,
    className: undefined,
  };

  render() {
    const { title, onClick, href, color, slideColor, size, fullWidth, disabled, targetBlank, className } = this.props;

    const buttonProps = {
      className: classNames(
        'button',
        size === 'small' && 'small',
        fullWidth && 'fullWidth',
        slideColor && 'changeColor',
        disabled && 'disabled',
        className,
      ),
      style: (slideColor
        ? { backgroundImage: `linear-gradient(90deg, ${color} 0%, ${color} 50%, ${slideColor} 50%, ${slideColor} 100%)` }
        : { backgroundColor: color }) as { [key: string]: string },
    };

    if (href) {
      return (
        <Link href={href} targetBlank={targetBlank} {...buttonProps}>
          {title}
        </Link>
      );
    } else if (onClick) {
      return (
        <button onClick={onClick} {...buttonProps}>
          {title}
        </button>
      )
    }

    return null;
  }
}

interface IProps {
  title: ReactNode;
  color: string;
  onClick?: (e: any) => void;
  href?: string;
  size?: 'small' | 'large';
  slideColor?: string;
  fullWidth?: boolean;
  disabled?: boolean;
  targetBlank?: boolean;
  className?: string;
}