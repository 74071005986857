import { Columns, Column } from 'bloomer';
import React, { PureComponent } from 'react';
import { filter } from 'lodash';
import { bundleRoute } from 'routes';

import Link from 'components/Link';
import { bundleConfigs, IBundleConfig } from 'lib/bundles';
import { BLACK } from 'lib/colors';

import SectionHeading from '../SectionHeading';
import ShadowHeading from '../ShadowHeading';
import './styles.scss';

export default class BundlesList extends PureComponent<IProps> {
  static renderBundle = (config: IBundleConfig) => {

    return (
      <Column isSize={4} key={config.slug} className='bundle'>
        <Link href={bundleRoute(config.slug)} isRouterLink>
          <ShadowHeading type='h4' textColor={BLACK} shadowColor={config.mainColor}>{config.displayName}</ShadowHeading>
          <img src={`/bundles/${config.imageName}`} alt={`Kabundle kitchen bundle - ${config.displayName}`} className='image' />
        </Link>
      </Column>
    )
  }

  render() {
    const { excludedBundleConfig } = this.props;

    const configs = excludedBundleConfig
      ? filter(bundleConfigs, (config) => config.slug !== excludedBundleConfig.slug)
      : Object.values(bundleConfigs);

    return (
      <div className='bundlesList'>
        <SectionHeading text={excludedBundleConfig ? 'More Bundles' : 'Explore Bundles'} className='heading' />
        <Columns isMultiline>
          {configs.map((config) => BundlesList.renderBundle(config))}
        </Columns>
      </div>
    );
  }
}

interface IProps {
  excludedBundleConfig?: IBundleConfig;
}