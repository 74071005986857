import gql from 'graphql-tag';
import { useMutation, MutationHookOptions } from 'react-apollo';

import { RemoveProductFromCartMutation } from 'schema';

export const REMOVE_PRODUCT_FROM_CART_MUTATION = gql`
  mutation RemoveProductFromCartMutation($productId: String!, $cartId: String!) {
    removeProductFromCart(productId: $productId, cartId: $cartId)
  }
`;

export const useRemoveProductFromCartMutation = (options?: MutationHookOptions) => useMutation<RemoveProductFromCartMutation>(REMOVE_PRODUCT_FROM_CART_MUTATION, options);