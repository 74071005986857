import React from 'react';

import Link from 'components/Link';
import { IViewerConsumer, withViewerContext } from 'components/Layout/context';
import { HIGHLIGHT_COLOR } from 'lib/colors';
import routes from 'routes';

import CartIcon from './CartIcon';
import './styles.scss';

const NavigationBar = (props: IViewerConsumer) => {
  const { viewerContext: { cart, bundleConfig } } = props;

  const backgroundColor = bundleConfig?.mainColor || HIGHLIGHT_COLOR;

  return (
    <div className='navigationBar'>
      <div className='content'>
        <Link href={routes.home.path} className='logo' style={{ backgroundColor }} isRouterLink bold>
          Kabundle
        </Link>
        <div className='cart' style={{ backgroundColor }}>
          <CartIcon numProducts={cart?.products?.length || 0} />
        </div>
      </div>
    </div>
  );
};

export default withViewerContext(NavigationBar);
