import React, { ReactNode, PureComponent } from 'react';
import BootstrapOverlayTrigger from 'react-bootstrap/OverlayTrigger';
import BootstrapTooltip, { TooltipProps } from 'react-bootstrap/Tooltip';

export default class Tooltip extends PureComponent<IProps> {
  renderTooltip = (props: TooltipProps) => {
    const { text } = this.props;

    return (
      <BootstrapTooltip {...props}>{text}</BootstrapTooltip>
    );
  }

  render() {
    const { id, content } = this.props;

    return (
      <BootstrapOverlayTrigger placement='top' overlay={(props) => this.renderTooltip({ id, ...props })}>
        <>{content}</>
      </BootstrapOverlayTrigger>
    );
  }
}

interface IProps {
  id: string;
  text: string;
  content: ReactNode;
}