import gql from 'graphql-tag';
import { useQuery, QueryHookOptions } from 'react-apollo';

import { ViewerQuery_cart, ViewerQuery_viewer } from 'schema';

const VIEWER_QUERY = gql`
  query ViewerQuery($viewerId: ID, $cartId: ID) {
    viewer(id: $viewerId) {
      id
      __typename
    }
    cart(viewerId: $viewerId, cartId: $cartId) {
      id
      amazonCheckoutUrl
      products {
        id
        name
        imageUrl
        price
        rating
        ratingCount
        amazonProductUrl
        __typename
      }
      __typename
    }
  }
`;

export interface ViewerQuery {
  viewer: ViewerQuery_viewer;
  cart: ViewerQuery_cart;
};

export const useViewerQuery = (options?: QueryHookOptions) => useQuery<ViewerQuery>(VIEWER_QUERY, options);