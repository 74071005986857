import classNames from 'classnames';
import React, { PureComponent, ReactNode } from 'react';
import { sumBy } from 'lodash';

import Card from 'components/Card';
import SectionHeading from 'components/SectionHeading';
import { IViewerConsumer, withViewerContext } from 'components/Layout/context';
import Line from 'components/Line';
import Money from 'components/Money';
import Tooltip from 'components/Tooltip';
import { BundleQuery_bundle_products as IProduct } from 'schema';

import './styles.scss';

class OverviewCard extends PureComponent<IProps & IViewerConsumer> {
  static defaultProps = {
    title: undefined,
    isCart: false,
  }

  productInCart = (product: IProduct) => {
    const { viewerContext: { cart } } = this.props;

    return cart && cart.products.some((cartProduct) => cartProduct.id === product.id);
  }

  renderProductRow = (product: IProduct) => {
    const { isCart } = this.props;

    const inCart = this.productInCart(product);
    const grayProductsInCart = inCart && !isCart; // gray out items already in the cart on bundle pages, but not on the cart page

    const row = (
      <div key={product.id} className={classNames('productRow', grayProductsInCart && 'inCart')}>
        <div>{product.name}</div>
        {inCart && grayProductsInCart && (
          <div className='inCartTag'>IN CART</div>
        )}
        {/*{product.price && <Money amount={product.price} className='price' />}*/}
      </div>
    );

    if (grayProductsInCart) {
      return <Tooltip id={product.id} key={product.id} text='This product is already in your cart' content={row} />;
    }
    return row;
  }

  render() {
    const { products, button, color, title } = this.props;

    // const totalPrice = isCart
    //   ? sumBy(products, (p: IProduct) => p.price || 0)
    //   : sumBy(products, (p: IProduct) => this.productInCart(p) ? 0 : p.price || 0);

    return (
      <Card color={color} className='overview'>
        {title && <SectionHeading text={title} className='title' />}
        {products.map((product) => this.renderProductRow(product))}
        {/*<Line color={color} topSpacing bottomSpacing />*/}
        {/*<div className='productsTotalContainer'>*/}
        {/*  <Money amount={totalPrice} className='price' />*/}
        {/*</div>*/}
        <div className='overviewButton'>{button}</div>
      </Card>
    );
  }
}

interface IProps {
  products: IProduct[];
  button: ReactNode;
  color: string;
  title?: string;
  isCart?: boolean;
}

export default withViewerContext<IProps>(OverviewCard);