import { Columns, Column } from 'bloomer';
import { times } from 'lodash';
import React from 'react';

import Button from 'components/Button';
import ProductCard from 'components/ProductCard';
import ProductCardSkeleton from 'components/ProductCardSkeleton';
import { IViewerConsumer, withViewerContext } from 'components/Layout/context';
import { GRAY, HIGHLIGHT_COLOR } from 'lib/colors';
import { CartQuery_cart_products as IProduct } from 'schema';
import { useRemoveProductFromCartMutation } from 'graphql/mutations/removeProductFromCart';

const ProductCards = (props: IProps & IViewerConsumer) => {
  const { loading, viewerContext: { cart, removeProductFromCart } } = props;
  const cartId = cart?.id;
  const products = cart?.products || [];

  const [removeProductFromCartMutation] = useRemoveProductFromCartMutation({ variables: { cartId, productId: undefined }});

  const handleRemove = async (product: IProduct) => {
    removeProductFromCart(product);
    await removeProductFromCartMutation({ variables: { cartId, productId: product.id }});
  }

  const renderRemoveButton = (product: IProduct) => {
    return (
      <div className='buttonsContainer'>
        <Button onClick={() => handleRemove(product)} title='Remove' color={GRAY} />
      </div>
    );
  }

  if (loading) {
    return (
      <Columns isMultiline className='cards'>
        {times(4, (index) => (
          <Column key={index} isSize={{ default: 6 }}>
            <ProductCardSkeleton color={GRAY} />
          </Column>
        ))}
      </Columns>
    );
  }

  return (
    <Columns isMultiline className='cards'>
      {products.map((product) => (
        <Column key={product.id} isSize={{ default: 6 }}>
          <ProductCard
            product={product}
            mainColor={HIGHLIGHT_COLOR}
            secondaryColor={GRAY}
            renderButtons={renderRemoveButton}
          />
        </Column>
      ))}
    </Columns>
  );
}

interface IProps {
  loading: boolean;
}

export default withViewerContext<IProps>(ProductCards);