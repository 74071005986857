import classNames from 'classnames';
import React, { PureComponent } from 'react';

import Link from 'components/Link';
import { BoxIcon } from 'lib/icons';
import routes from 'routes';

import './styles.scss';

export default class CartIcon extends PureComponent<IProps> {
  render() {
    const { numProducts, className } = this.props;

    const countBubble = numProducts && numProducts > 0
      ? <div className='bubble'>{numProducts}</div>
      : undefined;

    return (
      <Link href={routes.cart.path} className={classNames(className, 'cartIcon')} isRouterLink bold>
        {BoxIcon(24)}{countBubble}
      </Link>
    );
  }
}

interface IProps {
  numProducts?: number;
  className?: string;
}