import classNames from 'classnames';
import React, { PureComponent } from 'react';

import './styles.scss';

export default class ShadowHeading extends PureComponent<IProps> {
  render() {
    const { children, type, textColor, shadowColor } = this.props;

    const headingProps = {
      className: classNames('shadowHeading', type),
      style: { color: textColor, textShadow: type === 'h1' ? `4px 3px 0 ${shadowColor}` : `3px 2px 0 ${shadowColor}` },
    };

    if (type === 'h1') {
      return <h1 {...headingProps}>{children}</h1>;
    } else if (type === 'h2') {
      return <h2 {...headingProps}>{children}</h2>;
    } else if (type === 'h3') {
      return <h3 {...headingProps}>{children}</h3>;
    } else if (type === 'h4') {
      return <h4 {...headingProps}>{children}</h4>
    }

    return null;
  }
}

interface IProps {
  children: string;
  type: 'h1' | 'h2' | 'h3' | 'h4';
  textColor: string;
  shadowColor: string;
}