import { filter, some } from 'lodash';
import pluralize from 'pluralize';
import React from 'react';

import Button from 'components/Button';
import { IViewerConsumer, withViewerContext } from 'components/Layout/context';
import { useAddProductsToCartMutation } from 'graphql/mutations/addProductsToCart';
import { IBundleConfig } from 'lib/bundles';
import { ViewerQuery_cart_products as IProduct } from 'schema';

const AddToCartButton = (props: IProps & IViewerConsumer) => {
  const { products, bundleConfig, viewerContext: { viewerId, cart, addProductsToCart } } = props;
  const cartId = cart?.id;

  const [addProductsToCartMutation] = useAddProductsToCartMutation({ variables: { viewerId, cartId, productIds: products.map((p) => p.id) }});

  const productsToAdd = cart ? filter(products, (bundleProduct) => !some(cart.products, (cartProduct) => bundleProduct.id === cartProduct.id)) : [];

  const handleAdd = async () => {
    if (productsToAdd.length === 0) {
      return;
    }
    addProductsToCart(productsToAdd);
    await addProductsToCartMutation({ variables: { viewerId, productIds: productsToAdd.map((p) => p.id), cartId }});
  }

  return (
    <Button
      title={productsToAdd.length > 0 ? `Add ${pluralize('product', productsToAdd.length, true)} to cart` : 'All products in cart'}
      onClick={handleAdd}
      color={bundleConfig.mainColor}
      disabled={productsToAdd.length === 0}
      fullWidth
    />
  )
}

interface IProps {
  products: IProduct[];
  bundleConfig: IBundleConfig;
}

export default withViewerContext<IProps>(AddToCartButton);