import gql from 'graphql-tag';
import { useQuery, QueryHookOptions } from 'react-apollo';

import { BundlesQuery_bundles } from 'schema';

const BUNDLES_QUERY = gql`
  query BundlesQuery {
    bundles {
      id
      slug
      products {
        id
        name
        imageUrl
      }
    }
  }
`;

export interface BundlesQuery {
  bundles: BundlesQuery_bundles[];
};

export const useBundlesQuery = (options?: QueryHookOptions) => useQuery<BundlesQuery>(BUNDLES_QUERY, options);