import React, { PureComponent, ReactNode } from 'react';

import '../styles.scss';

export default class PageWrapper extends PureComponent<IProps> {
  render() {
    const { children } = this.props;

    return (
      <div className='pageWrapper'>
        {children}
      </div>
    );
  }
}

interface IProps {
  children: ReactNode;
}