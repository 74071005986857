import {
  CORAL, CORAL_DARK,
  LAVENDER, LAVENDER_DARK,
  LIME, LIME_DARK,
  MAROON, MAROON_DARK,
  PINK, PINK_DARK,
  TEAL, TEAL_DARK,
  YELLOW, YELLOW_DARK,
} from 'lib/colors';

export interface IBundleConfig {
  slug: string;
  displayName: string;
  blurb: string;
  imageName: string;
  mainColor: string;
  secondaryColor: string;
}

export const bundleConfigs: { [key: string]: IBundleConfig } = {
  pizza: {
    slug: 'pizza',
    displayName: 'Pizza Party',
    blurb: 'Everything you need to make homemade pizza from scratch',
    imageName: 'pizza.png',
    mainColor: CORAL,
    secondaryColor: CORAL_DARK,
  },
  bananaBread: {
    slug: 'banana-bread',
    displayName: 'Bananas for Banana Bread',
    blurb: 'You\'ve seen everyone else make it. Now it\'s your turn.',
    imageName: 'bananabread.png',
    mainColor: LAVENDER,
    secondaryColor: LAVENDER_DARK,
  },
  mixology: {
    slug: 'bartending',
    displayName: 'Backyard Bartending',
    blurb: 'If you ever see your friends again, you\'ll be able to impress them',
    imageName: 'bartending.png',
    mainColor: LIME,
    secondaryColor: LIME_DARK,
  },
  fun: {
    slug: 'fun',
    displayName: 'Just For Fun',
    blurb: 'The gadgets you\'ve wanted since you were a kid',
    imageName: 'fun.png',
    mainColor: PINK,
    secondaryColor: PINK_DARK,
  },
  salad: {
    slug: 'salad',
    displayName: 'Step Up Your Salads',
    blurb: 'Start 2021 off right',
    imageName: 'salad.png',
    mainColor: TEAL,
    secondaryColor: TEAL_DARK,
  },
  cookies: {
    slug: 'cookies',
    displayName: 'WFH Cookies',
    blurb: 'Working from home doesn\'t have to be boring',
    imageName: 'cookies.png',
    mainColor: YELLOW,
    secondaryColor: YELLOW_DARK,
  },
  professional: {
    slug: 'professional',
    displayName: 'Upgrade like a Pro',
    blurb: 'Professional tools to bring the restaurant home',
    imageName: 'professional.png',
    mainColor: MAROON,
    secondaryColor: MAROON_DARK,
  }
}