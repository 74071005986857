import { find } from 'lodash';
import React from 'react';

import Button from 'components/Button';
import { IViewerConsumer, withViewerContext } from 'components/Layout/context';
import { useAddProductsToCartMutation } from 'graphql/mutations/addProductsToCart';
import { useRemoveProductFromCartMutation } from 'graphql/mutations/removeProductFromCart';
import { IBundleConfig } from 'lib/bundles';
import { ViewerQuery_cart_products as IProduct } from 'schema';

const AddToCartButton = (props: IProps & IViewerConsumer) => {
  const { product, bundleConfig, viewerContext: { viewerId, cart, addProductsToCart, removeProductFromCart } } = props;
  const cartId = cart?.id;

  const [addProductsToCartMutation] = useAddProductsToCartMutation({ variables: { viewerId, cartId, productIds: [product.id] }});
  const [removeProductFromCartMutation] = useRemoveProductFromCartMutation({ variables: { cartId, productId: product.id }});

  const handleAdd = async () => {
    addProductsToCart([product]);
    await addProductsToCartMutation({ variables: { viewerId, productIds: [product.id], cartId }});
  }

  const handleRemove = async () => {
    removeProductFromCart(product);
    await removeProductFromCartMutation({ variables: { cartId, productId: product.id }});
  }

  const inCart = product && cart?.products && !!find(cart.products, (p) => p.id === product.id);

  const buttonTitle = () => {
    return inCart ? 'Remove from cart' : 'Add to cart';
  }

  return (
    <Button
      title={buttonTitle()}
      onClick={inCart ? handleRemove : handleAdd}
      color={inCart ? bundleConfig.secondaryColor : bundleConfig.mainColor}
      fullWidth
    />
  )
}

interface IProps {
  product: IProduct;
  bundleConfig: IBundleConfig;
}

export default withViewerContext<IProps>(AddToCartButton);