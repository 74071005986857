import classNames from 'classnames';
import React, { PureComponent } from 'react';

import './styles.scss';

export default class SectionHeading extends PureComponent<IProps> {
  static defaultProps = {
    className: undefined,
  };

  render() {
    const { text, className } = this.props;

    return (
      <div className={classNames('sectionHeading', className)}>
        {text}
      </div>
    );
  }
}

interface IProps {
  text: string;
  className?: string;
}