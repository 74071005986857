import React, { ComponentType, createContext } from 'react';

import { IBundleConfig } from 'lib/bundles';
import { ViewerQuery_cart as ICart, ViewerQuery_cart_products as IProduct } from 'schema';

export interface IViewerConsumer {
  viewerContext: {
    viewerId?: string,
    bundleConfig?: IBundleConfig;
    cart?: ICart;
    addProductsToCart: (products: IProduct[]) => void;
    removeProductFromCart: (product: IProduct) => void;
  }
}

const Context = createContext<IViewerConsumer>({} as IViewerConsumer);
export const ViewerProvider = Context.Provider;

export function withViewerContext<T>(Component: ComponentType<T & IViewerConsumer>) {
  return function ContextComponent(props: any) {
    return (
      <Context.Consumer>
        {value => <Component {...props} {...value} />}
      </Context.Consumer>
    );
  }
}