import classNames from 'classnames';
import React, { PureComponent, ReactNode } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import './styles.scss';

export default class Link extends PureComponent<IProps> {
  static defaultProps = {
    bold: false,
    underlined: false,
    targetBlank: false,
    isRouterLink: false,
    className: undefined,
    style: undefined,
  };

  render() {
    const { children, href, bold, underlined, targetBlank, isRouterLink, className, style } = this.props;

    const klassNames = classNames(
      'link',
      bold && 'bold',
      underlined && 'underlined',
      className,
    );

    if (isRouterLink) {
      return (
        <RouterLink to={href} key={href} className={klassNames} style={style}>{children}</RouterLink>
      )
    }

    return (
      <a
        href={href}
        target={targetBlank ? '__blank' : undefined}
        rel='noopener noreferrer'
        className={klassNames}
        style={style}
      >
        {children}
      </a>
    );
  }
}

interface IProps {
  children: ReactNode;
  href: string;
  bold?: boolean;
  underlined?: boolean;
  targetBlank?: boolean;
  isRouterLink?: boolean;
  className?: string;
  style?: { [key: string]: string };
}