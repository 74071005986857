import React, { PureComponent, ReactNode } from 'react';

import Card from 'components/Card';
import Line from 'components/Line';
import Link from 'components/Link';
import Money from 'components/Money';
import StarRating from 'components/StarRating';
import { IProductsConsumer, withProductsContext } from 'containers/Bundle/context';
import { BundleQuery_bundle_products as IProduct } from 'schema';

import './styles.scss';

class ProductCard extends PureComponent<IProps & IProductsConsumer> {
  renderProductInfo = () => {
    const { product, mainColor } = this.props;

    const name = <div className='name'>{product.name}</div>;

    return (
      <div className='info'>
        <div>
          {product.amazonProductUrl ? <Link href={product.amazonProductUrl} className='bold' targetBlank>{name}</Link> : name}
          {/*{product.rating && product.ratingCount && (*/}
          {/*  <div className='rating'>*/}
          {/*    <StarRating name={product.name} value={product.rating} color={mainColor} /> &nbsp;({product.ratingCount})*/}
          {/*  </div>*/}
          {/*)}*/}
        </div>
        {/*{product.price && <Money amount={product.price} className='bold' />}*/}
      </div>
    );
  }

  render() {
    const { product, secondaryColor, renderButtons } = this.props;

    return (
      <Card color={secondaryColor} className='productCard'>
        <Link href={product.amazonProductUrl} targetBlank>
          <div className='imgContainer'>
            <img src={product.imageUrl!} alt={product.name} className='image' />
          </div>
          <Line color={secondaryColor} bottomSpacing />
        </Link>
        {this.renderProductInfo()}
        {renderButtons(product)}
      </Card>
    );
  }
}

interface IProps {
  product: IProduct;
  mainColor: string;
  secondaryColor: string;
  renderButtons: (product: IProduct) => ReactNode;
}

export default withProductsContext<IProps>(ProductCard);