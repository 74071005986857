import gql from 'graphql-tag';
import { useMutation, MutationHookOptions } from 'react-apollo';

import { AddCheckoutClickMutation } from 'schema';

const ADD_CHECKOUT_CLICK_MUTATION = gql`
  mutation AddCheckoutClickMutation($cartId: String!) {
    addCheckoutClick(cartId: $cartId)
  }
`;

export const useAddCheckoutClickMutation = (options?: MutationHookOptions) => useMutation<AddCheckoutClickMutation>(ADD_CHECKOUT_CLICK_MUTATION, options);