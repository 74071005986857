import { Column, Columns } from 'bloomer';
import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

import BundlesList from 'components/BundlesList';
import Hero from 'components/Hero';
import { IViewerConsumer, withViewerContext } from 'components/Layout/context';
import OverviewCard from 'components/OverviewCard';
import ShadowHeading from 'components/ShadowHeading';
import { useBundleQuery } from 'graphql/queries/bundle';
import { BLACK } from 'lib/colors';
import { BundleQuery_bundle_products as IProduct } from 'schema';

import AddAllToCartButton from './AddAllToCartButton';
import { ProductsProvider } from './context';
import EmptyState from './EmptyState';
import ProductCards from './ProductCards';
import './styles.scss';

const Bundle = (props: IViewerConsumer) => {
  const { viewerContext: { bundleConfig } } = props;
  const [selectedProducts, setSelectedProducts] = useState<IProduct[]>([]);

  // Get bundle from slug
  const splitPath = useLocation().pathname.split('/').filter((v) => v);
  const bundleSlug = splitPath[1];

  // Fetch bundle
  const bundleQuery = useBundleQuery({ variables: { slug: bundleSlug } });

  if (bundleQuery.error || !bundleConfig || (!bundleQuery.loading && !bundleQuery.data?.bundle)) {
    return <EmptyState />;
  }

  const bundle = bundleQuery.data?.bundle;
  const products = bundle?.products;

  if (products && isEmpty(selectedProducts)) {
    setSelectedProducts(products);
  }

  const unselectProduct = (product: IProduct, reason: string) => {
    const updatedSelectedProducts = selectedProducts.filter((p) => p.id !== product.id);
    setSelectedProducts(updatedSelectedProducts);
  };

  const renderProductCards = () => {
    if (!bundleQuery.loading && !products) {
      return 'Bundle is empty';
    }

    return (
      <ProductCards
        bundleConfig={bundleConfig}
        products={bundleQuery.loading ? [] : products}
        loading={bundleQuery.loading}
      />
    );
  }

  const renderOverviewCard = () => {
    if (!products) {
      return null;
    }

    return (
      <OverviewCard
        products={products}
        button={<AddAllToCartButton products={products} bundleConfig={bundleConfig} />}
        color={bundleConfig.secondaryColor}
        title='Everything you need'
      />
    );
  }

  return (
    <ProductsProvider
      value={{
        productsContext: {
          products: selectedProducts,
          unselectProduct,
        }
      }}
    >
      <Hero backgroundColor={bundleConfig.mainColor} centeredImageName={`/bundles/${bundleConfig.imageName}`} />
      <Columns>
        <Column isSize={8}>
          <ShadowHeading type='h3' textColor={BLACK} shadowColor={bundleConfig.mainColor}>
            {bundleConfig.displayName}
          </ShadowHeading>
          {renderProductCards()}
        </Column>
        <Column isSize={4}>
          {renderOverviewCard()}
        </Column>
      </Columns>
      <BundlesList excludedBundleConfig={bundleConfig} />
    </ProductsProvider>
  );
};

// interface IState {
//   selectedProducts: ProductsQuery_products[];
//   setSelectedProducts: (products: ProductsQuery_products[]) => void;
// }

export default withViewerContext(Bundle);