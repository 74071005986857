import { Column, Columns } from 'bloomer';
import pluralize from 'pluralize';
import React from 'react';

import BundlesList from 'components/BundlesList';
import Button from 'components/Button';
import Hero from 'components/Hero';
import OverviewCard from 'components/OverviewCard';
import ShadowHeading from 'components/ShadowHeading';
import { IViewerConsumer, withViewerContext } from 'components/Layout/context';
import { useAddCheckoutClickMutation } from 'graphql/mutations/addCheckoutClick';
import { BLACK, HIGHLIGHT_COLOR, HIGHLIGHT_COLOR_DARK } from 'lib/colors';

import ProductCards from './ProductCards';

const Cart = (props: IViewerConsumer) => {
  const cart = props.viewerContext.cart;

  const [addCheckoutClickMutation] = useAddCheckoutClickMutation({ variables: { cartId: cart?.id || '' }});

  const renderProductCards = () => {
    if (cart?.products?.length === 0) {
      return <div>Cart is empty</div>;
    }

    return <ProductCards loading={!cart} />;
  }

  const handleClick = (e: any) => {
    e.preventDefault();
    addCheckoutClickMutation();
  }

  const renderOverviewCard = () => {
    if (!cart || !cart.amazonCheckoutUrl) {
      return null;
    }

    return (
      <OverviewCard
        products={cart.products}
        button={(
          <Button
            title='Check out on Amazon'
            href={cart.amazonCheckoutUrl}
            onClick={handleClick}
            color={HIGHLIGHT_COLOR}
            fullWidth
            targetBlank
          />
        )}
        color={HIGHLIGHT_COLOR_DARK}
        title={pluralize('Product', cart.products.length, true)}
        isCart
      />
    )
  };

  return (
    <>
      <Hero backgroundColor={HIGHLIGHT_COLOR} centeredImageName='donuts.png' />
      <Columns isMultiline>
        <Column isSize={8}>
          <ShadowHeading type='h3' textColor={BLACK} shadowColor={HIGHLIGHT_COLOR}>Cart</ShadowHeading>
          {renderProductCards()}
        </Column>
        <Column isSize={4}>
          {renderOverviewCard()}
        </Column>
      </Columns>
      <BundlesList />
    </>
  );
};

export default withViewerContext<{}>(Cart);