import { Columns, Column } from 'bloomer';
import { times } from 'lodash';
import React, { PureComponent } from 'react';

import Button from 'components/Button';
import ProductCard from 'components/ProductCard';
import ProductCardSkeleton from 'components/ProductCardSkeleton';
import { IBundleConfig } from 'lib/bundles';
import { BundleQuery_bundle_products as IProduct } from 'schema';

import AddToCartButton from '../AddToCartButton';
import { IProductsConsumer, withProductsContext } from '../context';
import './styles.scss';

class ProductCards extends PureComponent<IProps & IProductsConsumer> {
  renderUnselectButton = (product: IProduct, title: string) => {
    const { bundleConfig, productsContext: { unselectProduct } } = this.props;

    return (
      <Button title={title} onClick={() => unselectProduct(product, title)} color={bundleConfig.secondaryColor} size='small' className='button' />
    );
  };

  renderButtons = (product: IProduct) => {
    const { bundleConfig } = this.props;

    return (
      <div className='buttonsContainer'>
        <AddToCartButton product={product} bundleConfig={bundleConfig} />
        {/*<div className='unselectButtons'>*/}
        {/*  {this.renderUnselectButton(product, 'Already have')}*/}
        {/*  {this.renderUnselectButton(product, "Don't need")}*/}
        {/*  {this.renderUnselectButton(product, "Don't want")}*/}
        {/*</div>*/}
      </div>
    );
  }

  render() {
    const { bundleConfig, loading, productsContext: { products } } = this.props;

    if (loading) {
      return (
        <Columns isMultiline className='cards'>
          {times(4, (index) => (
            <Column key={index} isSize={{ default: 6 }}>
              <ProductCardSkeleton color={bundleConfig.secondaryColor} twoButtonRows />
            </Column>
          ))}
        </Columns>
      )
    }

    return (
      <Columns isMultiline className='cards'>
        {products.map((product) => (
          <Column key={product.id} isSize={{ default: 6 }}>
            <ProductCard
              product={product}
              mainColor={bundleConfig.mainColor}
              secondaryColor={bundleConfig.secondaryColor}
              renderButtons={this.renderButtons}
            />
          </Column>
        ))}
      </Columns>
    );
  }
}

interface IProps {
  bundleConfig: IBundleConfig;
  products: IProduct[];
  loading: boolean;
}

export default withProductsContext<IProps>(ProductCards);